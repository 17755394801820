[data-theme="dark"] {
  // primary
  --primary-main: #0d1a2e;
  --primary-light: #16243c;
  --primary-dark: #0b1423;
  --secondary-main: #192d45;
  --secondary-light: #21365a;
  --secondary-dark: #1a2b47;

  --success-gradient: linear-gradient(
    180deg,
    #6cce2f 0%,
    #279027 99.99%,
    #3f80e9 100%
  );
  --primary-gradient: linear-gradient(180deg, #10b3e8 0%, #3f80e9 100%);
  --sky-gradient: linear-gradient(312deg, #04C3FF 2.96%, #79DEFE 76.16%, #79DEFF 94.84%);

  // text
  --text-primary-main: #10b3e8;
  --text-secondary-main: #ffffff;
  --text-secondary-normal: #d9d9d9;
  --text-secondary-light: #ffffff;
  --text-logo: #d9d9d9;
  --text-error-page: #53698d;
  --text-desc: #577298;

  // table
  --table-header-bg: #0b1423;
  --table-body-bg: #0F1F39;
  --table-body-bg-primary: #16243c;
  --table-body-bg-hover: #1a2b47;
  --table-body-bg-secondary: #16243c;
  --table-header-cell-primary: #d9d9d9;
  --table-column-separator: #38455e;
  --table-cell-border: #0b1423;
  --table-cell-border-colored: #304e83;
  --table-cell-bg-swap: #323232;
  --table-cell-bg-buy: #6CCE2F;
  --table-cell-bg-sell: #FF3F35;
  --table-scrollbar-thumb-primary: #263f69;
  --table-scrollbar-track-primary: #132035;
  --table-pagination-text-primary: #7080a4;
  --table-pagination-text-active-primary: #10b3e8;
  --table-pagination-tab-active-bg: #222e3c;
  --table-pagination-nav-bg: #222e3C;
  --table-pagination-nav-text: #10b3e8;
  --table-column-sort-icons: #355792;
  --table-column-sort-icons-active: #d9d9d9;
  --table-column-overlay-layer: #10b3e8;
  --table-menu-shadow: rgba(11, 20, 35, 0.8);
  --table-body-bg-buy: rgba(108, 206, 47, 0.15);
  --table-body-bg-sell: rgba(255, 63, 53, 0.15);

  // layout
  --side-menu-bar: #132035;

  // others
  --tab-active-gradient: linear-gradient(90deg, #FFF 0%, #04C1FF 0.01%, #0062FF 100%);
  --tab-bg: #1A2B47;
  --badge-bg: rgba(15, 31, 57, 0.50);
  --badge-bg-active: rgba(15, 31, 57, 0.50);
  --snackbar-bg: #42627b;
  --snackbar-text: #f9f9f9;
  --loader-primary: #ffffff;
  --card-divider-primary: #414b5d;
  --tooltip-bg-primary: #0b1423;
  --tooltip-text-primary: #d9d9d9;
  --divider-primary: #273344;
  --input-bg: #0F1F39;
  --input-text: #59789C;
  --dropdown-select: rgba(25, 118, 210, 0.08);
  --info-circle-bg: #21365a;
  --filter-input-bg: #15243b;
  --starter-plan-bg: #121f35;
  --border-primary: #28426C;
  --logo-color: #d9d9d9;
  --border-secondary: #28426C;
  --bg-secondary: transparent;
  --switch-thumb: #ffffff;
  --switch-thumb-disabled: #5a6675;
  --switch-track: linear-gradient(180deg, #10b3e8 0%, #3f80e9 100%);
  --nomai-active-deposits: #1a2b47;

  // custom colors
  --color-orange: #ff772a;
  --color-purple: #f653ef;
  --color-purple-secondary: #9c3be7;
  --color-red: #ff3f35;
  --color-blue: #2b40ff;
  --color-light-blue: #a0e3f8;
  --color-dark-blue: #10b3e8;
  --color-green: #6cce2f;
  --color-light-green: #00eac0;
  --color-yellow: #fccc21;
  --color-light-yellow: #d5ff5e;
  --color-gray: #5a6675;
  --color-light-gray: #d9d9d9;
  --color-medium-gray: #7a94b3;
  --color-dark-gray: #21365a;
  --color-sky: #10b3e8;
  --color-sky-dark: #098eba;
  --color-gray-white: #e0e0e0;
  --color-white: #ffffff;
  --color-black: #000;

  //web3Modal colors
  --wui-color-modal-bg: var(--secondary-dark);
  --wui-cover: rgba(0, 0, 0, 0.3);
}

[data-theme="light"] {
  // primary
  --primary-main: #f3f6fb;
  --primary-light: #ffffff;
  --primary-dark: #ebf0f9;
  --secondary-main: #e0eafa;
  --secondary-light: #f8f8f8;
  --secondary-dark: #e9edf4;

  --success-gradient: linear-gradient(
    180deg,
    #6cce2f 0%,
    #279027 99.99%,
    #3f80e9 100%
  );
  --primary-gradient: linear-gradient(180deg, #10b3e8 0%, #3f80e9 100%);
  --sky-gradient: linear-gradient(312deg, #04C3FF 2.96%, #79DEFE 76.16%, #79DEFF 94.84%);

  // text
  --text-primary-main: #10b3e8;
  --text-secondary-main: #323232;
  --text-secondary-normal: #323232;
  --text-secondary-light: #f5fafe;
  --text-logo: #565b62;
  --text-error-page: #53698d;
  --text-desc: #577298;

  // table
  --table-header-bg: #ebf0f9;
  --table-body-bg: #ffffff;
  --table-body-bg-primary: #f2f6fd;
  --table-body-bg-hover: #f2f6fd;
  --table-body-bg-secondary: #ffffff;
  --table-header-cell-primary: #565b62;
  --table-column-separator: #e0e0e0;
  --table-cell-border: #ebf0f9;
  --table-cell-border-colored: #c5d4ed;
  --table-cell-bg-swap: #323232;
  --table-cell-bg-buy: #6CCE2F;
  --table-cell-bg-sell: #FF3F35;
  --table-scrollbar-thumb-primary: #c9d6ee;
  --table-scrollbar-track-primary: #e4eaf6;
  --table-pagination-text-primary: #1A2B47;
  --table-pagination-text-active-primary: #FFFFFF;
  --table-pagination-tab-active-bg: #1A2B47;
  --table-pagination-nav-bg: #FFFFFF;
  --table-pagination-nav-text: #1A2B47;
  --table-column-sort-icons: #919192;
  --table-column-sort-icons-active: #253141;
  --table-column-overlay-layer: #dde5f4;
  --table-menu-shadow: rgba(11, 20, 35, 0.5);
  --table-body-bg-buy: rgba(108, 206, 47, 0.15);
  --table-body-bg-sell: rgba(255, 63, 53, 0.15);

  // layout
  --side-menu-bar: #ffffff;

  // others
  --tab-active-gradient: linear-gradient(180deg, #FFF 0%, #04C1FF 0.01%, #0062FF 100%);
  --tab-bg: #FFFFFF;
  --svg-bg: #1A2B47;
  --badge-bg: #FFFFFF;
  --badge-bg-active: #9E9E9E;
  --snackbar-bg: #94bfe0;
  --snackbar-text: #f9f9f9;
  --loader-primary: #0b1423;
  --card-divider-primary: #94bfe0;
  --tooltip-bg-primary: #0b1423;
  --tooltip-text-primary: #d9d9d9;
  --divider-primary: #f6fafd;
  --input-bg: #eef3fb;
  --input-text: #59789C;
  --dropdown-select: rgba(25, 118, 210, 0.08);
  --info-circle-bg: #ffffff;
  --filter-input-bg: #f7f7f7;
  --starter-plan-bg: #e4eaf6;
  --border-primary: #28426C;
  --logo-color: #000;
  --border-secondary: none;
  --bg-secondary: #ffffff;
  --switch-thumb: linear-gradient(312deg, #04C3FF 2.96%, #79DEFE 76.16%, #79DEFF 94.84%);
  --switch-thumb-disabled: #c4c4c4;
  --switch-track: #1A2B47;
  --nomai-active-deposits: #dde1e7;

  // custom colors
  --color-orange: #ff772a;
  --color-purple: #f653ef;
  --color-purple-secondary: #9c3be7;
  --color-red: #ff3f35;
  --color-blue: #2b40ff;
  --color-light-blue: #a0e3f8;
  --color-dark-blue: #10b3e8;
  --color-green: #6cce2f;
  --color-light-green: #00eac0;
  --color-yellow: #fccc21;
  --color-light-yellow: #d5ff5e;
  --color-gray: #c4c4c4;
  --color-light-gray: #2a394b;
  --color-medium-gray: #7a94b3;
  --color-dark-gray: #6a94b3;
  --color-sky: #10b3e8;
  --color-sky-dark: #098eba;
  --color-gray-white: #b6c6e7;
  --color-white: #ffffff;
  --color-black: #000;
}
