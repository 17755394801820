@import url("./colors.scss");
@import url("./variables.scss");
@import url("./buttons.scss");

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important;
  }
  .desktop {
    display: none !important;
  }
  .tablet-desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .mobile {
    display: none !important;
  }
  .desktop {
    display: none !important;
  }
  .mobile-desktop {
    display: none !important;
  }
}

@media only screen and (min-width: 1201px) {
  .mobile {
    display: none !important;
  }
  .tablet {
    display: none !important;
  }
  .mobile-tablet {
    display: none !important;
  }
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;

  &:-webkit-any-link {
    color: var(--text-primary-main);
  }
}

.alphanomics-logo {
  .logo-path {
    fill: var(--logo-color);
  }
}

.page-title {
  font-family: var(--font-dm-sans-regular);
  font-size: 24px;
  font-weight: 500;
  color: var(--text-secondary-main);
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.dm-sans-400 {
  font-family: "dm-sans-regular", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif !important;
  font-weight: 400;
}

.dm-sans-500 {
  font-family: "dm-sans-medium", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif !important;
  font-weight: 500 !important;
}

.dm-sans-600 {
  font-family: "dm-sans-bold", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif !important;
}

.main-container {
  padding: 24px 40px 116px;

  @media only screen and (max-width: 1200px) {
    padding: 24px 32px 40px;
  }

  @media only screen and (max-width: 768px) {
    padding: 24px 16px 40px;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin: -8px;
    margin-bottom: 8px;

    .tabs-container {
      margin: 8px;
    }

    .page-title {
      margin: 8px;
    }

    .header-right {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-width: 100%;

      @media (max-width: 768px) {
        .refresh-container {
          order: 1;
        }

        .page-size-selector-container {
          order: 2;
        }
      }

      & > * {
        margin: 8px;
      }
    }
  }
}

.display-none {
  display: none !important;
}

.d-flex {
  display: flex;
}

.display-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.text-primary-main {
  color: var(--text-primary-main);
}

.text-secondary-main {
  color: var(--text-secondary-main);
}

.text-secondary-normal {
  color: var(--text-secondary-normal);
}

.text-secondary-light {
  color: var(--text-secondary-light);
}

.text-primary {
  background: var(--text-primary-main) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;
}

.text-red {
  background: #f04400 !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
  text-fill-color: transparent !important;
}

.info-circle-icon {
  display: block;
}

.uniswap-icon {
  .background-circle {
    fill: var(--secondary-dark);
  }
}

.sushiswap-icon {
  .background-circle {
    fill: var(--secondary-dark);
  }
}

.one-inch-icon {
  .background-circle {
    fill: var(--secondary-dark);
  }
}

.color-red {
  color: var(--color-red) !important;
}

.color-green {
  color: var(--color-green) !important;
}

.recharts-tooltip-wrapper {
  border: none !important;
  outline: none !important;
  z-index: 1000;

  .custom-tooltip {
    padding: 15px 10px;
    border: none !important;
    border-radius: 10px;
    outline: none !important;
    background: var(--tooltip-bg-primary) !important;

    .token {
      .token-symbol {
        color: var(--tooltip-text-primary) !important;
      }
      margin-bottom: 5px;
    }

    p {
      white-space: nowrap !important;
      color: var(--tooltip-text-primary) !important;
    }

    &:focus {
      border: none !important;
      outline: none !important;
    }
  }
}

.toast-text {
  background-color: var(--snackbar-bg) !important;
  color: var(--snackbar-text) !important;
  white-space: pre-line;
}

.styled-input-container {
  label {
    padding-bottom: 4px;
    text-indent: 16px;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    color: var(--text-secondary-normal);
  }

  input {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    background-color: var(--input-bg);
    outline: none;
    border: none;
    font-size: 14px;
    text-align: left;
    padding: 0 16px;
    font-family: var(--font-dm-sans-regular);
    font-style: normal;
    color: var(--input-text);

    &::placeholder {
      color: var(--input-text);
    }
  }

  .input-text-field {
    width: 100%;

    .MuiOutlinedInput-notchedOutline {
      border-radius: 50px;
      border: none !important;
    }

    &:focus {
      .MuiOutlinedInput-notchedOutline {
        border: none !important;
      }
    }
  }
}

.MuiInputBase-root.select {
  display: flex;
  align-items: center;
  outline: none;
  padding: 0;

  .MuiInputBase-input {
    border-radius: 4px;
    padding: 0 16px;
    font-family: var(--font-dm-sans-medium);
    font-size: 14px;
    color: var(--text-primary-main);
    background: var(--primary-light);
    height: 36px;
    min-height: 36px;
    line-height: 36px;
  }

  &:has(.MuiSelect-iconOpen) {
    .MuiInputBase-input {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .MuiPaper-root {
      border-top: 1px solid var(--primary-main);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .dropdown-icon {
      rotate: 180deg;
    }
  }

  .MuiSelect-icon {
    display: none;
  }

  .dropdown-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 6px;
    transition: 0.3s ease-in-out;

    .background-layer {
      fill: var(--primary-light);
    }
  }

  .MuiList-root {
    padding: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  .MuiPaper-root {
    background: var(--primary-light);
    border-radius: 0;
    max-height: 300px;
    overflow-y: auto;

    .MuiMenuItem-root {
      font-family: var(--font-dm-sans-regular);
      font-size: 14px;
      color: var(--text-primary-main);
      min-height: unset;

      &.Mui-selected {
        background-color: var(--dropdown-select);
      }
    }

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      height: 4px;
      width: 4px;
      border-radius: 10px;
      background: var(--text-primary-main);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--color-sky);
    }
  }
}

@keyframes nav-tab-name-slide-in {
  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
  }
}

@keyframes open-sub-menu {
  to {
    width: 250px;
    visibility: visible;
  }
}

.border-gradient {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: -1;
    border-radius: inherit;
    background-image: linear-gradient(to bottom, #10b3e8, #3f80e9);
  }
}

.MuiSnackbar-root {
  z-index: 10000 !important;
}

.has-watermark {
  position: relative;

  .watermark {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 2;
  }
}

.MuiAutocomplete-root {
  .MuiFormLabel-root {
    line-height: unset;
    left: 10px;
    color: var(--text-primary-main) !important;

    &.MuiFormLabel-filled {
      display: none !important;
    }
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    background: var(--primary-light);
    height: 46px;
    padding: 0 10px 0 16px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;

    .MuiAutocomplete-input {
      padding: 0 !important;
      font-size: 14px;
      font-family: var(--font-dm-sans-medium);
      color: var(--text-primary-main);
    }

    .MuiOutlinedInput-notchedOutline {
      display: none;
    }

    .dropdown-icon {
      display: flex;
      width: 30px;
      height: 30px;

      .background-layer {
        fill: var(--primary-light);
      }
    }
  }

  & + .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
    .MuiPaper-root {
      background: var(--primary-light);
      color: var(--text-primary-main);
      border-radius: 0 0 8px 8px;
      overflow-y: auto;
      border-top: 1px solid var(--primary-main);

      .MuiAutocomplete-noOptions {
        color: var(--text-secondary-main);
        padding: 12px 24px;
      }

      .MuiAutocomplete-listbox {
        padding: 0;

        .MuiAutocomplete-option {
          padding: 6px 16px;
          font-size: 14px;
        }

        &::-webkit-scrollbar {
          height: 4px;
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          height: 4px;
          width: 4px;
          border-radius: 10px;
          background: var(--text-primary-main);
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #10b3e8;
        }
      }
    }
  }

  &.Mui-focused {
    .MuiFormLabel-root {
      display: none;
    }
  }

  &.Mui-expanded {
    .MuiInputBase-root.MuiOutlinedInput-root {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .dropdown-icon {
      rotate: 180deg;
    }
  }
}

.nexus {
  position: relative;

  &:before {
    content: "NEXUS";
    top: -2px;
    right: -32px;
    height: 8px;
    line-height: 8px;
    position: absolute;
    text-align: center;
    border-radius: 2px;
    padding: 2px 4px;
    background: conic-gradient(from 180deg at 48.5% 50%, #FBFBFD 26.24999910593033deg, #C8D4DA 88.12500178813934deg, #FFF 156.58468008041382deg, #E5F4FF 191.74442768096924deg, #E3E9EE 237.1290135383606deg, #FAFBFC 255.19062280654907deg, #D6DFE6 310.1085305213928deg, #FFF 331.875deg);
    font-family: var(--font-dm-sans-medium);
    font-weight: 700;
    font-size: 8px;
    color: var(--color-black);
  }
}

.plus {
  position: relative;

  &:before {
    content: "PLUS";
    top: -2px;
    right: -32px;
    width: 20px;
    height: 8px;
    line-height: 8px;
    position: absolute;
    text-align: center;
    border-radius: 2px;
    padding: 2px 4px;
    background: conic-gradient(from 207deg at 35.71% 70.83%, #FFCB67 26.24999910593033deg, #F6DBA6 88.12500178813934deg, #FFEBC3 156.58468008041382deg, #FFF 191.74442768096924deg, #FFDEC6 225.95467329025269deg, #FFDAA3 278.6032176017761deg, #FFD771 331.875deg);
    font-family: var(--font-dm-sans-medium);
    font-weight: 700;
    font-size: 8px;
    color: var(--color-black);
  }
}

.switch {
  font-family: dm-sans-regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--text-secondary-normal);
  position: relative;


  .MuiSwitch-root.toggle-switch {
    width: 36px;
    height: 20px;
    padding: 0;

    .MuiSwitch-switchBase {
      padding: 0;

      &.Mui-checked {
        transform: translateX(16px) !important;
      }

      .MuiSwitch-thumb {
        width: 20px;
        height: 20px;
        margin: 0;
        background: var(--switch-thumb);
      }

      &.Mui-disabled {
        .MuiSwitch-thumb {
          background: var(--switch-thumb-disabled);
        }
      }
    }

    .MuiSwitch-track {
      margin: 0;
      height: 100%;
      border-radius: 10px;
      background: var(--switch-track);
      opacity: 1;
    }
  }
}

.MuiPickersPopper-paper {
  background-color: var(--primary-light) !important;
  color: var(--text-secondary-main) !important;

  .MuiPickersArrowSwitcher-button,
  .MuiDayCalendar-weekDayLabel,
  .MuiDateRangePickerDay-day {
    color: var(--text-secondary-main) !important;
  }

  .MuiPickersDay-today {
    border: 1px solid var(--text-secondary-main) !important;
  }
}

.MuiCheckbox-root {
  padding: 3px !important;

  .MuiSvgIcon-root {
    fill: var(--text-primary-main);
  }

  &.Mui-checked {
    padding: 6px !important;

    .MuiSvgIcon-root {
      fill: var(--text-primary-main);
      background-color: var(--color-white);
      border-radius: 4px;
      width: 18px;
      height: 18px;

      path {
        scale: 1.34;
        transform: translate(-3px, -3px);
      }
    }
  }
}

[data-theme="light"] {
  .info-circle-icon {
    .info-circle-background {
      fill: #1A2B47;
    }
  }

  svg {
    &.fill {
      path:not(.no-touch) {
        fill: var(--svg-bg);
      }

      &.white {
        path {
          fill: var(--color-white);
        }
      }

      &.gray {
        path {
          fill: #9E9E9E;
        }
      }
    }
    &.stroke {
      path:not(.no-touch) {
        stroke: var(--svg-bg);
      }

      &.white {
        path {
          stroke: var(--color-white);
        }
      }
    }
  }
}
