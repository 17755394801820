.footer-container {
  background-color: var(--primary-main);
  margin-left: var(--sidebar-width);
  border-radius: 8px;
  transition: all 0.2s ease-in-out;

  .footer-content {
    margin: 0 auto;
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    padding: 40px 80px;
    color: var(--text-secondary-normal);

    .feature-content {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      align-items: start;
      gap: 120px;

      .paragraph1 {
        display: grid;
        gap: 17px;
      }

      .paragraph2 {
        display: grid;
        gap: 13px;
      }

      .subtitle {
        font-family: var(--font-dm-sans-bold);
        font-size: 16px;
        background: var(--sky-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      a {
        font-size: 14px;
        color: var(--text-secondary-main);
      }
    }

    .logo-content {
      display: grid;
      gap: 20px;

      .logo-text {
        color: #A8ADB7;
        line-height: normal;
        font-size: 14px;
      }
    }

    .community-content {
      display: grid;
      gap: 20px;

      .join-community {
        font-family: var(--font-dm-sans-medium);
        font-size: 16px;
        color: var(--text-secondary-main);
      }

      .social-links {
        display: grid;
        grid-auto-flow: column;
        justify-content: start;
        gap: 12px;

        a {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 200px;
          background: var(--primary-light);
        }
      }
    }

    .subscribe-content {
      .bold {
        font-family: var(--font-dm-sans-medium);
        font-size: 16px;
        color: var(--text-secondary-main);
      }

      .input-box {
        padding: 0 6px 0 24px;
        border-radius: 80px;
        border: 1px solid var(--border-primary);
        display: flex;
        align-items: center;
        height: 48px;
        margin-top: 30px;
        margin-bottom: 12px;

        input {
          height: 34px;
          flex: 1;
          border: none;
          background: none;
          outline: none;
          font-size: 14px;
          color: var(--text-secondary-main);

          &::placeholder {
            color: var(--text-secondary-main);
          }
        }

        .button-primary {
          width: 115px;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
        }
      }

      .privacy-policy {
        font-size: 14px;
        color: #A8ADB7;

        span {
          text-decoration: underline;
          text-decoration-skip-ink: none;
          text-underline-offset: 2px;
        }
      }
    }

    .copyright-content {
      grid-column: span 2;
      display: grid;
      gap: 12px;
      color: #A8ADB7;

      .copyright {
        font-size: 14px;

        a {
          color: #A8ADB7;
          text-decoration: underline;
        }
      }

      .copyright-text {
        font-size: 12px;
      }
    }
  }
}

[data-theme="light"] {
  .footer-container {
    .footer-content {
      .subtitle {
        background: unset;
        -webkit-text-fill-color: unset;
        color: var(--text-secondary-main);
      }
    }
  }
}