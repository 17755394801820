.tutorial-dialog {
  .MuiDialog-paper {
    background-color: var(--secondary-light);
    padding: 40px 25px 30px;
    border-radius: 16px;
    width: 850px;
    height: 700px;
    position: relative;

    @media only screen and (max-width: 768px) {
      padding: 30px 16px;
    }

    .dialog-header {
      font-size: 24px;
      color: var(--text-secondary-main);
      text-align: center;
      position: relative;
      margin-bottom: 30px;

      .overview {
        font-size: 28px;
        font-weight: bold;
      }
    }

    .dialog-content {
      .nav-button-wrapper {
        height: auto;
        top: 50%;
        transform: translateY(-50%);

        button {
          background-color: var(--secondary-main);
          .MuiSvgIcon-root {
            fill: var(--text-primary-main);
          }
        }
      }

      button {
        display: inline-flex;
      }
    }

    .dialog-footer {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .button-primary {
        width: 150px;
      }
    }

    .tutorial-video {
      width: 800px;
      height: 450px;

      iframe {
        width: 100%;
        height: 100%;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;
        height: 50vh;
      }
    }
  }
}
