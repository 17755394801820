.button-disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.button-primary {
  background: var(--tab-active-gradient);
  border-radius: 100px;
  width: 200px;
  height: 40px;
  line-height: 40px;
  font-family: dm-sans-regular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: var(--color-white);
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:has(.MuiCircularProgress-root) {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiCircularProgress-svg {
      color: var(--loader-primary) !important;
    }
  }
}


[data-theme="dark"] {
  .button-primary-light {
    background: #1f3856;
    color: #fefefe;
  }
}

[data-theme="light"] {
  .button-primary-light {
    background: #f6f6f6;
    color: #0e0e0e;
  }
}
