.sidebar-container {
  height: calc(100vh - var(--header-height) - var(--notification-height));
  width: var(--sidebar-width);
  position: fixed;
  top: 0;
  left: 0;
  margin-top: calc(var(--header-height) + var(--notification-height));
  z-index: 1000;
  background-color: var(--side-menu-bar);
  transition: all 0.2s ease-in-out;

  *, :before {
    transition: all 0.2s ease-in-out;
  }

  .text-primary-main {
    background: var(--sky-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .nexus,
  .plus {
    &:before {
      right: -10px;
      top: 4px;
    }
  }

  .chain-selector-container {
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    border-bottom: 6px solid var(--primary-dark);
    position: relative;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    z-index: 2;

    .sidebar-toggler {
      position: absolute;
      right: -12px;
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      cursor: pointer;
      background: #21365a;
      color: var(--text-primary-main);
      z-index: 2;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .menu-container {
    height: calc(100vh - 96px - var(--header-height) - var(--notification-height) - 2px);

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--side-menu-bar);
    }

    &::-webkit-scrollbar-thumb {
      height: 4px;
      width: 4px;
      border-radius: 10px;
      background: var(--table-scrollbar-thumb-primary);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #10b3e8;
    }

    .quick-access-links {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 6px solid var(--primary-dark);
      font-family: var(--font-dm-sans-regular);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      padding: 16px;
      overflow: hidden;

      .title {
        height: 0;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        line-height: 16px;
        color: var(--text-secondary-main);
      }

      .link {
        height: 35px;
        display: grid;
        justify-content: flex-start;
        grid-auto-flow: column;
        align-items: center;
        gap: 12px;
        padding: 0 12px;
        border-radius: 6px;
        width: 100%;
        margin-bottom: 2px;

        &.disabled {
          .name {
            color: var(--color-gray) !important;
            background-clip: unset;
            background: unset;
            -webkit-text-fill-color: unset;
          }
        }

        .name {
          white-space: nowrap;
          overflow: hidden;
          width: 0;
        }

        &:hover {
          background-color: var(--secondary-light);
        }

        &.active {
          background-color: var(--secondary-light);

          .name {
            background-clip: unset;
            background: unset;
            -webkit-text-fill-color: unset;
            color: var(--text-secondary-main) !important;
          }
        }
      }
    }

    .dex-toolkit-links,
    .discovery-alpha-links  {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-bottom: 6px solid var(--primary-dark);
      align-items: flex-start;
      // position: relative;

      .dropdown-icon {
        opacity: 0;
        z-index: 1;

        .background-layer {
          fill: var(--primary-light);
        }
      }

      .tg-opened {
        .dropdown-icon {
          transform: rotate(-180deg);
        }
      }

      .title {
        align-items: center;
        display: grid;
        grid-template-columns: 24px 1fr 24px;
        gap: 8px;
        cursor: pointer;
        padding: 8px 12px;
        margin-bottom: 2px;
        border-radius: 6px;
        white-space: nowrap;
        width: 100%;

        &:hover {
          background-color: var(--secondary-light);
        }

        .d-flex {
          justify-content: center;
        }

        .name {
          width: 0;
          font-size: 16px;
          line-height: 24px;
          overflow: hidden;
        }
      }

      .links {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        opacity: 0;
        overflow: hidden;
        max-height: 0;
        padding-left: 12px;
        padding-right: 12px;

        a {
          line-height: 18px;
          color: var(--text-secondary-main);
          border-radius: 6px;
          display: block;
          width: 100%;
          padding: 6px 20px;
          margin-bottom: 2px;
          white-space: nowrap;

          &:hover {
            background-color: var(--secondary-light);
          }

          &.active {
            background-clip: unset;
            -webkit-text-fill-color: unset;
            background: var(--secondary-light);
          }
        }

        .link {
          width: 100%;
          display: flex;
          align-items: center;

          a {
            flex: 1;
          }

          &.child {
            padding-left: 20px;
            opacity: 0;
            max-height: 0;
            overflow: hidden;

            &.opened {
              opacity: 1;
              max-height: 32px;
            }
          }
        }
      }
    }

    .link-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 16px;
      margin-top: 18px;

      .sidebar-link {
        padding: 12px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        &:hover {
          background-color: var(--secondary-light);
        }
      }
    }

    .theme-selector {
      padding: 16px;
      overflow: hidden;
      display: grid;
      gap: 8px;

      button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-dm-sans-regular);
        font-size: 16px;
        height: 32px;
        line-height: 32px;
        color: var(--text-secondary-main);
        border-radius: 6px;

        &:hover {
          background-color: var(--secondary-light);
        }

        svg {
          width: 36px;
          min-width: 36px;
        }

        a {
          transition: unset;
          white-space: nowrap;
          width: 0;
          overflow: hidden;
        }
      }

      .header-links {
        font-size: 16px;
        color: var(--text-secondary-main);
        display: block;
        line-height: 32px;
        padding: 0 16px 0 64px;
        border-radius: 6px;
        opacity: 0;

        &.text-primary-main {
          color: var(--text-primary-main);
        }

        &:hover {
          background-color: var(--secondary-light);
        }
      }
    }
  }

  .MuiPopper-root,
  .MuiPopperUnstyled-root {
    position: unset !important;
    top: 0 !important;
    right: unset !important;
  }

  .disabled {
    pointer-events: none;
    color: var(--color-gray) !important;
  }

  @media screen and (min-width: 1201px) {
    &:hover, &.locked {
      .nexus,
      .plus {
        &:before {
          right: 30px;
        }
      }

      .menu-container {
        overflow-y: auto;

        .quick-access-links {
          padding-right: 12px;

          .link {
            gap: 8px;

            .name {
              width: 100%;
            }
          }

          .title {
            margin-bottom: 5px;
            margin-left: 12px;
            height: 16px;
          }
        }

        .dex-toolkit-links,
        .discovery-alpha-links {
          padding-right: 12px;

          .dropdown-icon {
            opacity: 1;
          }

          .title {
            .name {
              width: 100%;
            }
          }

          &.show-child {
            .links {
              max-height: 400px;
              opacity: 1;
            }

            .title {
              .dropdown-icon {
                transform: rotate(-180deg);
              }
            }
          }
        }

        .link-container {
          flex-direction: row;
        }

        .theme-selector {
          button {
            padding: 0 12px;

            a {
              width: 100%;
            }
          }

          .header-links {
            opacity: 1;
          }
        }
      }
    }
  }

  //Mobile View
  @media only screen and (max-width: 1200px) {
    margin-top: 0;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out;

    .nexus,
    .plus {
      &:before {
        right: 30px;
      }
    }

    .menu-container {
      overflow-y: auto;

      .quick-access-links {
        padding-right: 12px;

        .link {
          gap: 8px;

          .name {
            width: 100%;
          }
        }

        .title {
          margin-bottom: 5px;
          margin-left: 12px;
          height: 16px;
        }
      }

      .dex-toolkit-links,
      .discovery-alpha-links {
        padding-right: 12px;

        .dropdown-icon {
          opacity: 1;
        }

        .title {
          .name {
            width: 100%;
          }
        }

        &.show-child {
          .links {
            max-height: 400px;
            opacity: 1;
          }

          .title {
            .dropdown-icon {
              transform: rotate(-180deg);
            }
          }
        }
      }

      .link-container {
        flex-direction: row;
      }

      .theme-selector {
        button {
          padding: 0 12px;

          a {
            width: 100%;
          }
        }

        .header-links {
          opacity: 1;
        }
      }
    }

    &.opened {
      transform: translateX(0);
    }

    .menu-container {
      height: calc(100vh - 80px);
    }

    .chain-selector-container {
      justify-content: space-between;
      height: 80px;
      padding-right: 12px;
    }
  }
}

.sidebar-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(19, 32, 53, 0.4);
  backdrop-filter: blur(1.5px);
  z-index: 999;
}

[data-theme="light"] {
  .sidebar-container {
    .chain-selector-container {
      .sidebar-toggler {
        svg {
          path {
            fill: var(--color-white);
          }
        }
      }
    }

    .text-primary-main {
      background: unset;
      -webkit-text-fill-color: unset;
      color: var(--text-secondary-main);
    }

    .theme-selector {
      rect {
        fill: #1A2B47;
      }
    }
  }
}
