* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "dm-sans-medium";
  src: url("/public/assets/fonts/dm-sans/dmsans-medium-webfont.woff2")
      format("woff2"),
    url("/public/assets/fonts/dm-sans/dmsans-medium-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dm-sans-regular";
  src: url("/public/assets/fonts/dm-sans/dmsans-regular-webfont.woff2")
      format("woff2"),
    url("/public/assets/fonts/dm-sans/dmsans-regular-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dm-sans-bold";
  src: url("/public/assets/fonts/dm-sans/dmsans-bold-webfont.woff2")
      format("woff2"),
    url("/public/assets/fonts/dm-sans/dmsans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  src: url("/public/assets/fonts/ubuntu/Ubuntu-R.woff") format("woff");
}

@font-face {
  font-family: "Neue Montreal";
  font-style: normal;
  font-weight: 400;
  src: url("/public/assets/fonts/neue-montreal/NeueMontreal-Light.woff")
    format("woff");
}

body {
  font-family: "dm-sans-regular", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}
