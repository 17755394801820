.chain-selector {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 192px;
  }

  .MuiInputBase-root.select {
    width: 100%;
    transition: all 0.2s ease-in-out;
    pointer-events: none;

    .MuiInputBase-input {
      padding: 0;
      display: flex !important;
      align-items: center;
      background: var(--secondary-light);
      height: 44px;
      min-height: 44px;
      line-height: 44px;
      border-radius: 4px;
      font-family: var(--font-dm-sans-medium);
      font-size: 16px;

      .chain-icon-container {
        min-width: 24px;
        width: 24px;
        height: 24px;
        margin-left: 12px;
        margin-right: 8px;
        transition: all 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .chain-label {
        opacity: 0;
        white-space: nowrap;
        transition: all 0.2s ease-in-out;
        background: var(--sky-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .dropdown-icon {
      width: 0;
      transition: all 0.2s ease-in-out;
      top: 10px;
    }

    .MuiPaper-root {
      background: var(--secondary-dark);
      max-height: unset;

      .MuiMenuItem-root {
        padding: 6px 20px;
        font-size: 16px;

        .chain-icon-container {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .chain-label {
          font-family: var(--font-dm-sans-medium);
          background: var(--sky-gradient);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .MuiInputBase-root.select {
      pointer-events: unset;

      .MuiInputBase-input {
        padding: 0 8px;

        .chain-label {
          opacity: 1;
        }
      }

      .dropdown-icon {
        width: 24px;
      }
    }
  }
}

@media screen and (min-width: 1201px) {
  .sidebar-container:hover,
  .sidebar-container.locked {
    .chain-selector {
      .MuiInputBase-root.select {
        pointer-events: unset;

        .MuiInputBase-input {
          padding: 0 8px;

          .chain-label {
            opacity: 1;
          }
        }

        .dropdown-icon {
          width: 24px;
        }
      }
    }
  }
}

[data-theme="light"] {
  .chain-selector {
    .MuiInputBase-root.select {
      .MuiInputBase-input {
        background-color: #f4f7fb;
      }
    }

    .chain-label {
      color: var(--text-secondary-main);
      background: unset !important;
      -webkit-text-fill-color: unset !important;
    }
  }
}
