.search-container {
  width: 100%;
  height: 40px;
  position: relative;
  background-color: var(--secondary-main);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;

  .token {
    width: 100%;
    padding-right: 4px;

    .token-symbol {
      color: var(--text-secondary-normal);
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.search-container-with-results {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;;

    &:before {
      background-color: var(--color-sky);
      background-image: unset;
    }
  }

  .search-icon-container {
    cursor: pointer;
    margin-right: 4px;

    .search-icon {
      display: flex;
    }
  }

  input {
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--text-secondary-normal);

    &::placeholder {
      font-family: var(--font-dm-sans-regular);
      font-style: normal;
      font-size: 16px;
      line-height: 21px;

      color: var(--input-text);
    }
  }

  .loading-container {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .results-container {
    width: 100%;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--table-cell-border);
    margin-top: 1px;
    position: absolute;
    transform: translateY(100%);
    z-index: 99;

    .bg {
      background: var(--secondary-main);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      margin: 0;

      &.border-gradient:before {
        top: 0;
      }
    }

    .results-wrapper {
      padding: 16px;

      .results {
        max-height: 200px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background-color: var(--side-menu-bar);
        }

        &::-webkit-scrollbar-thumb {
          width: 6px;
          border-radius: 3px;
          background: var(--text-primary-main);
        }

        .title {
          font-size: 12px;
          color: var(--text-primary-main);
          font-family: var(--font-dm-sans-medium);
          margin-bottom: 10px;
        }

        .item-details {
          display: flex;
          font-family: var(--font-dm-sans-medium) !important;
          padding: 8px 0px;
          cursor: pointer;

          .address {
            margin-left: 0px !important;
          }
        }

        .empty-result-container {
          font-size: 12px;
          display: grid;
          gap: 10px;
          margin-bottom: 16px;

          .first-para {
            color: var(--color-gray);
          }
          .second-para {
            color: var(--color-light-gray);
          }
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--table-cell-border);
    margin-bottom: 16px;
  }

  .recent-searches-container {
    .heading {
      padding-left: 16px;
      padding-right: 16px;
      font-size: 12px;
    }

    .recent-searches {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-top: 8px;
      padding-bottom: 16px;
      padding-left: 12px;
      padding-right: 12px;

      .recent-item {
        margin: 4px;
        border-radius: 10px;
        padding: 8px 16px;
        font-family: var(--font-dm-sans-medium);
        font-weight: 500;
      }
    }
  }

  //Mobile View
  @media only screen and (max-width: 768px) {
    margin-top: 12px;

    &.search-container-with-results {
      border-radius: 8px;
    }

    .results-container {
      border-top: 0;

      .bg {
        &.border-gradient {
          &:before {
            content: unset;
          }

          .results-wrapper {
            .results {
              max-height: 300px;
            }
          }

          .recent-searches-container {
            padding: 0 16px;
            .heading {
              padding: 0;
            }

            .recent-searches {
              padding: 16px 0;
            }
          }
        }
      }
    }
  }
}

[data-theme="light"] {
  .search-container {
    border: 2px solid #1A2B47;
    background: var(--color-white);

    .search-icon-container {
      .search-icon {
        path {
          stroke: #1A2B47;
        }
      }
    }

    .results-container {
      .bg {
        border: 2px solid #1A2B47;
        border-top: none;
        background: var(--color-white);
        margin: 0 -2px -2px;
      }
    }
  }
}