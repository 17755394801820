.context-menu {
  .MuiMenu-paper {
    background-color: var(--secondary-light) !important;
    backdrop-filter: blur(5px);
    transform-origin: left;
    animation: menuAnimation 0.4s 0s both;

    .MuiList-root {
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 0;
    }

    .MuiMenuItem-root {
      color: var(--text-secondary-main) !important;
      font-size: 14px;
      animation: menuItemAnimation 0.2s 0s both;

      &:nth-child(1) {
        animation-delay: 0s;
      }
    }
  }

  @keyframes menuAnimation {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      height: auto;
      opacity: 1;
      border-radius: 8px;
      transform: scale(1);
    }
  }

  @keyframes menuItemAnimation {
    0% {
      opacity: 0;
      transform: translateX(-10px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
