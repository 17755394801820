body::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

body::-webkit-scrollbar-thumb {
  height: 4px;
  width: 4px;
  border-radius: 10px;
  background: var(--text-primary-main);
}

body::-webkit-scrollbar-thumb:hover {
  background: var(--color-sky);
}

body {
  background: var(--primary-dark);
}

.suspense-container {
  background-color: var(--primary-dark);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
