:root {
  --sidebar-width: 80px;
  --header-height: 80px;
  --notification-height: 0px;
}

.layout-container {
  position: relative;

  &:has(.notification-bar) {
    --notification-height: 26px;
  }

  @media screen and (min-width: 1201px) {
    &:has(.sidebar-container:hover),
    &:has(.sidebar-container.locked) {
      --sidebar-width: 280px;
    }
  }

  @media screen and (max-width: 1200px) {
    --sidebar-width: 280px;
  }

  .layout-content-container {
    display: flex;
    min-height: calc(100vh - 323px);

    .content-container {
      width: 100%;
      padding-top: calc(var(--header-height) + var(--notification-height));
      padding-left: var(--sidebar-width);
      transition: all 0.2s ease-in-out;

      @media only screen and (max-width: 1200px) {
        padding-left: 0;
      }

      &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        height: 8px;
        width: 8px;
        border-radius: 10px;
        background: white;
      }
    }
  }

  .mobile-search + .layout-content-container {
    display: none;
  }
}
