.not-found-container,
.error-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 403px);

  h1 {
    font-family: "dm-sans-regular";
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 73px;
    color: var(--text-error-page);
  }

  h5 {
    font-family: "dm-sans-regular";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: var(--text-secondary-main);
    margin-top: 16px;
    margin-bottom: 24px;
  }
}
