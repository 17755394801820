.notification-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 998;
  height: var(--notification-height);;
  line-height: var(--notification-height);;
  background: var(--primary-gradient);
  font-family: var(--font-dm-sans-medium);
  font-size: 13px;
  color: var(--color-white);
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  span {
    display: inline-block;
    animation: none;
  }

  .button-close {
    position: absolute;
    right: 10px;
    top: 1px;
    display: flex;
    color: white;
  }

  &.overflow {
    span {
      animation: marquee 10s linear infinite;
    }
  }

  @keyframes marquee {
    0% {
      transform: translateX(100%); /* Start from right */
    }
    100% {
      transform: translateX(-100%); /* End on the left */
    }
  }
}